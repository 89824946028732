<template>
  <div class="verify-main">
    <div v-if="!name">Oops! Link not available</div>
    <div v-else class="verify-content">
      <div class="title">Hi {{ name }}</div>
      <div>Thank you for registering with ToDo</div>
      <div>To verify your email address, please enter the verification code below.</div>
      <div>The verification code was sent to {{ email }}</div>
      <div class="code-area">
        <input type="text" v-model="code1" @input="codeChanged($event, 1)" :disabled="codeSuccess != ''">
        <input type="text" v-model="code2" @input="codeChanged($event, 2)" ref="block2" :disabled="codeSuccess != ''">
        <input type="text" v-model="code3" @input="codeChanged($event, 3)" ref="block3" :disabled="codeSuccess != ''">
        <input type="text" v-model="code4" @input="codeChanged($event, 4)" ref="block4" :disabled="codeSuccess != ''">
      </div>
      <Button @click="submitCode" :load="submitLoading" :disabled="codeSuccess != ''">Submit</Button>
      <div class="error">{{ codeError }}</div>
      <div class="success">{{ codeSuccess }}</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: function() {
    return {
      name: '',
      email: '',

      code1: '',
      code2: '',
      code3: '',
      code4: '',

      codeError: '',
      codeSuccess: '',

      submitLoading: false
    }
  },
  props: {
  },
  methods: {
    codeChanged: function(e, num) {
      if (e.target.value) {
        if (e.target.value.length > 1) {
          this[`code${num}`] = this[`code${num}`][1];
        }

        if (num < 4) {
          this.$refs[`block${num + 1}`].focus();
        }
      }
    },
    submitCode: async function() {
      this.codeError = '';
      this.codeSuccess = '';
      if (!this.code1 || !this.code2 || !this.code3 || !this.code4) {
        this.codeError = 'Please enter a 4 character code';
        return;
      }

      this.submitLoading = true;
      let code = `${this.code1}${this.code2}${this.code3}${this.code4}`;
      try {
        await this.$store.getters.axios.get(`/Todo/User/VerifyCode?username=${this.name}&code=${code}`);
        this.codeSuccess = 'Verification Successful!';
      } catch (e) {
        this.codeError = e.response.data;
      }
      this.submitLoading = false;
    }
  },
  async mounted() {
    this.name = this.$route.query['username'];

    if (this.name) {
      try {
        let user = (await this.$store.getters.axios.get(`/Todo/User/UserDetailsByName?name=${this.name}`)).data;
        if (!user['verify']) {
          this.name = '';
          return;
        }
        let mailSplit = user['email'].split('@');
        this.email = `${user['email'].substring(0, 3)}xxxxxxxxxxx${mailSplit[1] ? `@${mailSplit[1]}` : ''}`;
      } catch (e) {
        this.name = '';
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.verify-main {
  text-align: center;

  > .verify-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .code-area {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;

      > input {
        margin: 5px;
        font-size: 1.5em;
        width: 20px;
        text-align: center;
        padding: 10px;
      }
    }
  }
}

.title {
  font-weight: bold;
  font-size: 1.3em;
}
</style>