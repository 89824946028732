<template>
  <div class="profile-main">
    <div class="theme-container" :class="[ $store.state.defaultSettings['theme'] ]">
      <div class="title">Your Profile</div>
      <div>Username : {{ $store.state['userDetails'] ? $store.state['userDetails']['username'] : '' }}</div>
      <div>Email : {{ $store.state['userDetails'] ? $store.state['userDetails']['email'] : '' }}</div>
      <div>Email Status : {{ $store.state['userDetails'] ? !$store.state['userDetails']['verify'] ? 'Verified ✔' : 'Not verified ❌' : '' }}</div>
      <div>
        <span>Logged In Devices : {{ $store.state['userDetails'] ? `${$store.state['userDetails']['devices']} of 5 devices` : '' }}</span>
        <span class="link" v-if="$store.state['userDetails']" @click="logoutAll">Logout All Devices</span>
      </div>
      <div>
        <span>Password : xxxxxxxxxxx</span>
        <span class="link" @click="changePassword = !changePassword">{{ changePassword ? 'Cancel' : 'Change Password' }}</span>
      </div>
      <transition name="slide-open">
        <div v-if="changePassword" class="change-password">
          <Input label="Current Password" type="password" :disabled="newPassSuccess != ''" :text.sync="currentPassword"></Input>
          <Input label="New Password" type="password" :disabled="newPassSuccess != ''" :text.sync="newPass1"></Input>
          <Input label="Re-enter New Password" :disabled="newPassSuccess != ''" type="password" :text.sync="newPass2"></Input>
          <div class="success left-align-text">{{ newPassSuccess }}</div>
          <div class="error left-align-text">{{ newPassError }}</div>
          <Button @click="submitNewPass" :load="newPassLoading" :disabled="newPassSuccess != ''">Submit</Button>
        </div>
      </transition>
      <br>
      Telegram Account : {{ $store.state['userDetails']['telegramname'] ? $store.state['userDetails']['telegramname'] : 'No Telegram Account linked' }}
      <Button :load="linkLoad" :class="[ $store.state['userDetails']['telegramname'] ? 'danger' : '' ]"
        @click="linkUnlinkTelegram($store.state['userDetails']['telegramname'] ? 'Unlink Telegram' : 'Link Telegram')">{{ $store.state['userDetails']['telegramname'] ? 'Unlink Telegram' : 'Link Telegram' }}</Button>
      <div v-if="telegramLinkCode">
        <div>Your Telegram code is {{ telegramLinkCode }}</div><br>
        <div>How to link your Telegram</div>
        <div>1. Please go to your Telegram and search for @reeqzantodobot<br>
          or click <a href="https://t.me/reeqzantodobot" target="_blank" class="link" style="margin: 0">here</a> to go to the chat
        </div>
        <div>2. Then send <b>linkme {{ $store.state['userDetails']['username'] }}-{{ telegramLinkCode }} to the bot</b></div>
        <div>3. Once linked, you can refresh this page<br>to make sure that you Telegram account is linked</div>
      </div>
      <br><br>
      <Button @click="$store.state.userDetails.firstlogin = true">How To Use</Button>
    </div>
    <br><br>
    <div class="theme-container" :class="[ $store.state.defaultSettings['theme'] ]">
      <div v-if="userStats" class="title">Statistics</div>
      <div v-if="userStats">Your ToDo Items : {{ userStats['TotalItem'] }}</div>
      <div v-if="userStats">Your ToDo Attachments : {{ userStats['TotalAttachments'] }}</div>
      <div v-if="userStats">Your Completed ToDo : {{ userStats['TotalCompleted'] }}</div>
      <div v-if="userStats">Your Pending ToDo : {{ userStats['TotalPending'] }}</div>
      <Button @click="deleteClicked('DeleteAllItems')" class="danger" v-if="userStats" :disabled="userStats['TotalItem'] == 0">Delete All Items</Button>
      <Button @click="deleteClicked('DeleteCompletedItems')" class="danger" v-if="userStats" :disabled="userStats['TotalItem'] == 0">Delete Completed Items</Button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import swal from 'sweetalert2';

export default {
  components: {
  },
  data: function() {
    return {
      changePassword: false,

      currentPassword: '',
      newPass1: '',
      newPass2: '',

      newPassSuccess: '',
      newPassError: '',

      newPassLoading: false,

      userStats: null,

      linkLoad: false,
      telegramLinkCode: ''
    }
  },
  props: {
  },
  methods: {
    logoutAll: async function() {
      let confirmLogoutAll = await swal.fire({
        title: 'Logout All Devices',
        html: `By clicking the confirm button below, you'll be automatically logged out at all your devices including this one.<br><br>Confirm to logout from all devices?`,
        icon: 'warning',
        confirmButtonText: 'Confirm',
        confirmButtonColor: 'blue',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'red',
        showCancelButton: true
      });

      if (confirmLogoutAll.isConfirmed) {
        try {
          let location = this.$router.resolve('/');
          await this.$store.getters.axios.get(`/Todo/User/LogoutAll?username=${this.$store.state['userDetails']['username']}`);
          localStorage.removeItem('key');
          window.location.href = location.href;
        } catch (e) {
          console.log(e.response.data);
        }
      }
    },
    submitNewPass: async function() {
      this.newPassLoading = true;
      this.newPassSuccess = '';
      this.newPassError = '';
      
      if (!this.currentPassword || !this.newPass1 || !this.newPass2) {
        this.newPassError = 'Please fill in all the details above';
      } else if (this.newPass1 != this.newPass2) {
        this.newPassError = 'Please make sure your new password match';
      } else if (this.newPass1 == this.currentPassword) {
        this.newPassError = 'New password cannot be the same as the current password';
      } else {
        let confirmChange = await swal.fire({
          title: 'Changing Password',
          html: `By clicking the confirm button below, you'll be automatically logged out at all your other devices.<br><br>Confirm to change your password?`,
          icon: 'warning',
          confirmButtonText: 'Confirm',
          confirmButtonColor: 'blue',
          cancelButtonText: 'Cancel',
          cancelButtonColor: 'red',
          showCancelButton: true
        });
          
        if (confirmChange.isConfirmed) {
          try {
            await this.$store.getters.axios.post('/Todo/User/ChangePassword', {
              current: this.currentPassword,
              new: this.newPass1
            });
            this.newPassSuccess = 'Password Changed';
            this.$store.state['userDetails']['devices'] = 1;
          } catch (e) {
            this.newPassError = 'Please make sure your current password is correct';
          }
        }
      }

      this.newPassLoading = false;
    },
    getStats: async function() {
      try {
        this.userStats = (await this.$store.getters.axios.get('/Todo/GetStatistics')).data;
      } catch (e) {
        console.log(e);
      }
    },
    deleteClicked: async function(deleteText) {
      let html = `You are about to delete all your ${deleteText == 'DeleteCompletedItems' ? 'Completed' : ''} ToDo items.<br>
        ${deleteText == 'DeleteAllItems' ? 'This will also delete all the items that are still pending.<br>' : ''}
        <br>Are you very sure about this?<br>
        Once you click the delete button below, all your ${deleteText == 'DeleteCompletedItems' ? 'Completed' : ''} ToDo items will be gone for good and nothing will be able to undo this process.<br><br>
        ARE YOU SUPER SURE??`;
      
      let confirmDelete = await swal.fire({
        title: `DELETE ${deleteText == 'DeleteCompletedItems' ? 'COMPLETED' : 'ALL'} DATA`,
        html: html,
        icon: 'warning',
        confirmButtonText: 'Delete',
        confirmButtonColor: 'red',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'gray',
        showCancelButton: true
      });

      if (confirmDelete.isConfirmed) {
        this.$store.commit('isLoading', 'Deleting Data');
        try {
          await this.$store.getters.axios.delete(`/Todo/${deleteText}`);
          this.$store.commit('addMessage', { message: 'Items Deleted!', type: 'success' });
        } catch (e) {
          console.log(e);
          this.$store.commit('addMessage', { message: 'Error Deleting Items', type: 'error' });
        }
        this.$store.commit('isLoading', false);
        await this.getStats();
      }
    },
    linkUnlinkTelegram: async function(text)
    {
      if (text == 'Link Telegram') {
        this.linkLoad = true;
        this.telegramLinkCode = (await this.$store.getters.axios.get('/Todo/Telegram/GenerateTelegramCode')).data;
        this.linkLoad = false;
      } else {
        var confirmUnlink = await swal.fire({
          title: 'Unlink Telegram Account',
          text: 'Are you sure you want to unlink your telegram account?',
          showConfirmButton: true,
          confirmButtonText: 'Unlink',
          confirmButtonColor: 'red',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          cancelButtonColor: 'gray' ,
          icon: 'warning'
        });
        
        if (confirmUnlink.isConfirmed) {
          this.linkLoad = true;
          try {
            await this.$store.getters.axios.delete('/Todo/Telegram/UnlinkTelegram');
            this.$store.state['userDetails']['telegramname'] = '';
          } catch (error) {
            swal.fire({
              title: 'Error Unlink Telegram',
              text: 'There was an error occurred while trying to unlink your Telegram account. Please try again.',
              icon: 'error'
            })
          }
          this.linkLoad = false;
        }
      }
    }
  },
  async mounted() {
    this.getStats();
  },
  watch: {
    changePassword: function() {
      this.newPassSuccess = '';
      this.newPassError = '';
      this.currentPassword = '';
      this.newPass1 = '';
      this.newPass2 = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  margin-left: 10px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>