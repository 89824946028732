<template>
  <div v-if="!brokenLinkText" class="reset-pwd-main">
    <div>Hey {{ name }}</div>
    <div>Enter your new password below</div>
    <Input label="New Password" :text.sync="newPass1" type="password" :disabled="resetSuccess != ''"></Input>
    <Input label="Re-enter New Password" :text.sync="newPass2" type="password" :disabled="resetSuccess != ''"></Input>

    <div class="success">{{ resetSuccess }}</div>
    <div class="error">{{ resetError }}</div>

    <Button @click="submitPassword" :disabled="resetSuccess != ''">Confirm New Password</Button>
  </div>
  <div v-else class="reset-pwd-main">
    {{ brokenLinkText }}
  </div>
</template>

<script>
export default {
  components: {
  },
  data: function() {
    return {
      name: '',
      pwdReset: '',

      newPass1: '',
      newPass2: '',

      resetSuccess: '',
      resetError: '',

      brokenLinkText: 'Loading . . .'
    }
  },
  props: {
  },
  methods: {
    submitPassword: async function() {
      this.resetError = '';
      this.resetSuccess = '';

      if (!this.newPass1 || !this.newPass2) {
        this.resetError = 'Please enter your new password twice';
      } else if (this.newPass1 != this.newPass2) {
        this.resetError = 'Please make sure that your password match';
      } else {
        try {
          let postData = {
            username: this.name,
            password: this.newPass1,
            resetkey: this.pwdReset
          };
          await this.$store.getters.axios.post('Todo/Password/Reset', postData);

          this.resetSuccess = 'Successfully reset your password!';
        } catch (e) {
          this.resetError = 'There was an error while resetting your password, please try again';
        }
      }
    }
  },
  async mounted() {
    let link = this.$route.query['link'];
    if (link) {
      this.name = link.split('_')[0];
      this.pwdReset = parseInt(link.split('_')[1]);

      try {
        await this.$store.getters.axios.get(`/Todo/Password/Validate?username=${this.name}&key=${this.pwdReset}`);

        let nowMill = new Date().getTime();
        if (nowMill < this.pwdReset) {
          this.brokenLinkText = '';
        } else {
          this.brokenLinkText = 'Opps.. This link has expired. Please request a new password reset to reset your password';
        }
      } catch (e) {
        this.brokenLinkText = 'Opps.. Link is not available';
      }
    } else {
      this.brokenLinkText = 'Opps.. Link is not available';
    }
  }
};
</script>

<style lang="scss" scoped>
.reset-pwd-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 10px;
  max-width: 768px;

  > Button {
    margin-top: 5px;
  }
}
</style>