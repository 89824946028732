import Vue from 'vue'
import App from './App.vue'

import Router from 'vue-router'

import LoginRegisterPage from './Pages/LoginRegisterPage'
import HomePage from './Pages/HomePage'
import VerifyPage from './Pages/VerifyPage'
import ResetPasswordPage from './Pages/ResetPasswordPage'

import ProfilePage from './Pages/Home/ProfilePage'
import TodoListPage from './Pages/Home/TodoListPage'
import SettingsPage from './Pages/Home/SettingsPage'

import VueX from './js/vuex.js'

import Input from './components/Input'
import Button from './components/Button'
import Tutorial from './components/Tutorial'
Vue.component('Input', Input)
Vue.component('Button', Button)
Vue.component('Tutorial', Tutorial)

Vue.config.productionTip = false

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '/', component: LoginRegisterPage, meta: { title: 'Todo - Login' } },
    { path: '/Verify', component: VerifyPage, meta: { title: 'Todo - Verify' } },
    { path: '/ResetPassword', component: ResetPasswordPage, meta: { title: 'Todo - Reset Password' } },
    { path: '/Home', component: HomePage, meta: { title: 'Todo - Home' },
      children: [
        { path: '/', component: TodoListPage, meta: { title: 'Todo - Home' } },
        { path: 'Profile', component: ProfilePage, meta: { title: 'Todo - Profile' } },
        { path: 'Settings', component: SettingsPage, meta: { title: 'Todo - Settings' } },
      ]
    },
    // { path: '/Chat', component: ChatPage, meta: { title: 'Chat' } }
  ],
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  next()
})

new Vue({
  render: h => h(App),
  router: router,
  store: VueX
}).$mount('#app')