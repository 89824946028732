<template>
  <div class="login-main">
    <div class="logo-container" :class="[ pageWidth < 768 ? 'top' : 'left' ]" ref="logoContainer" :style="{ transform: pageWidth < 768 ? `translateX(0%)` : `translateX(${isLogin ? 0 : 100}%)` }">
      <img src="https://icon-library.com/images/todo-icon/todo-icon-7.jpg" alt="">
      <div class="title">Sync all your ToDo list in all your devices</div>
      <div>
        {{ showLogin ? 'Sign in to view your ToDo lists' : 'Register by filling in the details' }}
        <br>
        OR
      </div>
      <button @click="registerLoginClicked">{{ showLogin ? 'Register' : 'Login' }}</button>
    </div>
    <div class="login-register-container" :class="[ pageWidth < 768 ? 'bottom' : 'right' ]"  ref="inputContainer" :style="{ transform: pageWidth < 768 ? `translateX(0%)` : `translateX(${isLogin ? 0 : -100}%)` }">
      <div class="title">{{ resetPassword ? 'Forgot Password' : loginRegisterTitle }}</div>
      <div v-if="showLogin">
        <Input :label="resetPassword ? 'Email' : 'Username'" :text.sync="loginUName" />
        <Input v-if="!resetPassword" label="Password" type="password" :text.sync="loginPassword" />
      </div>
      <div v-else>
        <Input label="Username" :text.sync="registerUName" @focusout="inputBlur('rUName')" :error.sync="rUNameError" :loading="rUNameLoading" :disabled="disabledRegisterFields" />
        <Input label="Email" :text.sync="registerEmail" @focusout="inputBlur('rEmail')" :error.sync="rEmailError" :loading="rEmailLoading" :disabled="disabledRegisterFields" />
        <Input label="Password" type="password" :text.sync="registerPassword1" :disabled="disabledRegisterFields" />
        <Input label="Re-enter Password" type="password" :text.sync="registerPassword2" :disabled="disabledRegisterFields" />
      </div>

      <Button @click="registerLogin(showLogin ? resetPassword ? 'Submit' : 'Login' : 'Register')" :disabled="disabledRegisterFields" style="margin-top: 10px; background-color: blue; color: white"
        :load="loggingIn || registering">
        {{ showLogin ? resetPassword ? 'Submit' : 'Login' : 'Register' }}
      </Button>
      <div class="reset-pwd" @click="resetPassword = !resetPassword">{{ resetPassword ? 'Login' : 'Forgot Password' }}</div>
      <div v-if="!showLogin" class="error">{{ registerError }}</div>
      <div v-if="!showLogin" class="success">{{ registerSuccess }}</div>
      <div v-if="showLogin" class="error">{{ loginError }}</div>
      <div v-if="showLogin" class="success">{{ loginSuccess }}</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: function() {
    return {
      isLogin: true,
      loginRegisterTitle: 'Login',
      showLogin: true,
      containerWidth: 0,
      pageWidth: 0,

      resetPassword: false,

      registerUName: '',
      registerEmail: '',
      registerPassword1: '',
      registerPassword2: '',

      rUNameLoading: false,
      rEmailLoading: false,

      rUNameError: '',
      rEmailError: '',

      registerError: '',
      registerSuccess: '',
      disabledRegisterFields: false,

      registering: false,
      loggingIn: false,

      loginUName: '',
      loginPassword: '',

      loginSuccess: '',
      loginError: '',
    }
  },
  props: {
  },
  methods: {
    registerLoginClicked: function() {
      this.isLogin = !this.isLogin;
      this.clearRegisterField();

      setTimeout(() => {
        this.loginRegisterTitle = this.isLogin ? 'Login' : 'Register';
        this.showLogin = this.isLogin;
      }, this.pageWidth < 768 ? 0 : 500);
    },
    pageResize: function() {
      this.containerWidth = this.$refs.logoContainer.getBoundingClientRect().width;
      this.pageWidth = window.innerWidth;
    },
    registerLogin: async function(item) {
      switch (item) {
        case 'Login':
          this.loginError = '';
          if (!this.loginUName || !this.loginPassword) {
            this.loginError = 'Please enter your username and password';
            return;
          }

          try {
            this.loggingIn = true;

            let loginDetails = (await this.$store.getters.axios.post('/Todo/User/Login', {
              username: this.loginUName,
              password: this.loginPassword
            })).data;
            localStorage.setItem('key', loginDetails);
            var location = this.$router.resolve('/Home');
            window.location.href = location.href;
          } catch (e) {
            this.loginError = e.response.data;
          }
          this.loggingIn = false;
          break;
        case 'Register':
          this.registerError = '';
          if (!this.registerUName || !this.registerEmail || !this.registerPassword1 || !this.registerPassword2) {
            this.registerError = 'Please fill in all the fields';
          } else if (this.rUNameError || this.rEmailError) {
            this.registerError = 'Please make sure all the fields are correct';
          } else if (this.registerPassword1 != this.registerPassword2) {
            this.registerError = 'Please make sure both of your password is the same';
          }

          if (this.registerError) {
            return;
          }
          
          this.registering = true;
          try {
            var register = (await this.$store.getters.axios.post('/Todo/User/Register', {
              username: this.registerUName,
              password: this.registerPassword1,
              email: this.registerEmail
            })).data;
            
            this.disabledRegisterFields = true;
            this.registerSuccess = 'Thank you for registering. You can now login using your username and password';
          } catch (e) {
            this.registerError = e.response.data;
          }
          this.registering = false;
          break;
        case 'Submit':
          this.loggingIn = true;
          this.loginError = '';
          try {
            await this.$store.getters.axios.get(`Todo/Password/RequestReset?email=${this.loginUName}`);

            this.loginSuccess = 'An email was sent to your email.';
          } catch (error) {
            this.loginError = 'Sorry, we could not found your email address';
          }
          this.loggingIn = false;
          break;
      }
    },
    inputBlur: async function(e) {
      switch (e) {
        case 'rUName':
          if (this.registerUName) {
            this.rUNameLoading = true;
            var uname = (await this.$store.getters.axios.get(`/Todo/User/CheckUsername?username=${this.registerUName}`)).data;
            this.rUNameLoading = false;
            if (!uname) {
              this.rUNameError = 'User ID is unavailable';
            }
          }
          break;
        case 'rEmail':
          if (this.registerEmail) {
            this.rEmailLoading = true;
            var email = (await this.$store.getters.axios.get(`/Todo/User/CheckEmail?email=${this.registerEmail}`)).data;
            this.rEmailLoading = false;
            if (!email) {
              this.rEmailError = 'Email is unavailable';
            }
          }
          break;
      }
    },
    clearRegisterField: function() {
      this.registerUName = '';
      this.registerEmail = '';
      this.registerPassword1 = '';
      this.registerPassword2 = '';

      this.rUNameLoading = false;
      this.rEmailLoading = false;

      this.rUNameError = '';
      this.rEmailError = '';

      this.registerError = '';
      this.registerSuccess = '';

      this.disabledRegisterFields = false;
    }
  },
  mounted() {
    this.pageResize();
    window.addEventListener('resize', () => { this.pageResize() });

    if (localStorage.getItem('key')) {
      this.$router.push('/Home');
    }
  },
  watch: {
    resetPassword: function(val) {
      this.loginSuccess = '';
      this.loginError = '';

      this.loginUName = '';
      this.loginPassword = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.login-main {
  display: flex;
  justify-content: center;
  width: calc(90% - 20px);
  max-width: 1024px;
  // background-color: red;
  padding: 10px;
  margin-top: 50px;
  border-radius: 50px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  box-shadow: 10px 10px 10px gray;
  overflow: hidden;
  height: 400px;
  position: relative;

  > .logo-container, > .login-register-container {
    flex-grow: 1;
    position: absolute;
    top: 0;
    transition: 1s;
    transition-timing-function: ease-in-out;
    background-color: white;
  }

  > .left, > .right {
    width: 50%;
    height: 100%;
  }

  > .left {
    left: 0;
    top: 0;
  }

  > .right {
    right: 0;
    top: 0;
  }

  > .top, > .bottom {
    width: 100%;
  }

  > .top {
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    // height: 20%;
    height: unset;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 20px !important;
    // overflow: hidden;
  }

  > .bottom {
    left: 0;
    height: 100%;
  }

  > .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: yellow;
    padding-top: 50px;
    z-index: 10;
    // left: -10px;
    // height: calc(100% - 50px);
    // top: -10px;

    > img {
      height: 50px;
    }

    > .title {
      font-size: 1.5em;
      font-weight: bold;
      padding: 20px;
      padding-bottom: 5px;
    }
  }

  > .login-register-container {
    box-sizing: border-box;
    // background-color: blue;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 2em;
    }

    > .reset-pwd {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 5px;
      cursor: pointer;
    }
  }
}

@media (max-width: 767px) {
  .login-main {
    height: calc(100% - 62px);
    position: absolute;
    top: 0;
    margin-top: 20px;
    flex-direction: column;

    > .logo-container, > .login-register-container {
      position: relative;
    }

    > .logo-container > .title {
      font-size: 1.2em;
    }

    > .login-register-container {
      justify-content: flex-start;
      padding-top: 20px;

      > .title {
        font-size: 1.5em;
      }
    }
  }
}
</style>