<template>
  <div class="settings-main">
    <div class="theme-container" :class="[ $store.state.defaultSettings['theme'] ]">
      <div class="title">Settings</div>
      <div>You can change the default settings below when loading your ToDo items</div><br>
      <div>By default your:</div>
      <div> <!-- Rows Per Page -->
        Rows per page :
        <select v-model="newSettings['rowsPerPage']" @change="dropdownChanged">
          <option v-for="(r, i) in $store.state.rowsPerPageList" :key="i" :value="r['value']">{{ r['text'] }}</option>
        </select>
      </div>
      <div> <!-- Status -->
        Status :
        <select v-model="newSettings['status']" @change="dropdownChanged">
          <option v-for="(s, i) in $store.state.statusList" :key="i" :value="s['value']">{{ s['text'] }}</option>
        </select>
      </div>
      <div> <!-- Category -->
        Category :
        <select v-model="newSettings['category']" @change="dropdownChanged">
          <option v-for="(c, i) in $store.state.categoryList" :key="i" :value="c['value']">{{ c['text'] }}</option>
        </select>
      </div>
      <div> <!-- Priority -->
        Priority :
        <select v-model="newSettings['priority']" @change="dropdownChanged">
          <option v-for="(p, i) in $store.state.priorityList" :key="i" :value="p['value']">{{ p['text'] }}</option>
        </select>
      </div>
      <div> <!-- Sorting -->
        Sorting :
        <select v-model="newSettings['sort']" @change="dropdownChanged">
          <option v-for="(s, i) in $store.state.sortingList" :key="i" :value="s['value']">{{ s['text'] }}</option>
        </select>
      </div>
      <div> <!-- Theme -->
        Theme :
        <select v-model="newSettings['theme']" @change="dropdownChanged">
          <option v-for="(t, i) in $store.state.themeList" :key="i" :value="t['value']">{{ t['text'] }}</option>
        </select>
      </div>
      
      <button v-if="gotChanges" @click="saveClicked">Save</button>
      <button class="danger-btn" v-if="gotChanges" @click="cancelClicked">Cancel</button>
    </div>

    <br>
    <button class="reset-btn danger-btn" v-if="notDefault" @click="resetToDefaultClicked">Reset to Default</button>

    <br><br>
    <div class="theme-container" :class="[ $store.state.defaultSettings['theme'] ]">
      <Input type="file" :multiFile="false" :files.sync="backgroundImage" label="Background Image"></Input>
      <button :disabled="backgroundImage.length == 0" @click="updateBackground('save')">Save Background Image</button>
      <button @click="updateBackground('clear')" class="danger-btn">Remove Background Image</button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: function() {
    return {
      gotChanges: false,
      newSettings: {},
      notDefault: false,
      
      backgroundImage: []
    }
  },
  props: {
  },
  methods: {
    saveClicked: async function() {
      this.$store.commit('isLoading', 'Saving Settings');
      try {
        await this.$store.getters.axios.patch('/Todo/Settings/UpdateSettings', this.newSettings);

        this.changeDefault();
        this.gotChanges = false; 
        this.compareSystemDefault();
        this.$store.commit('addMessage', { message: 'Settings Saved', type: 'success' });
      } catch (e) {
        this.$store.commit('addMessage', { message: 'Error Saving Settings', type: 'error' });
      }

      this.$store.commit('isLoading', false);
    },
    cancelClicked: function() {
      Object.keys(this.$store.state.defaultSettings).forEach(k => {
        this.newSettings[k] = this.$store.state.defaultSettings[k];
      });
      this.gotChanges = false;
    },
    dropdownChanged: function() {
      this.gotChanges = JSON.stringify(this.$store.state.defaultSettings) != JSON.stringify(this.newSettings);
    },
    changeDefault: function() {
      Object.keys(this.$store.state.defaultSettings).forEach(k => {
        this.$store.state.defaultSettings[k] = this.newSettings[k];
      });
    },
    resetToDefaultClicked: function() {
      Object.keys(this.newSettings).forEach(k => {
        this.newSettings[k] = this.$store.state.systemDefaultSettings[k];
      });
      this.dropdownChanged();
    },
    compareSystemDefault: function() {
      this.notDefault = JSON.stringify(this.newSettings) != JSON.stringify(this.$store.state.systemDefaultSettings);
    },
    updateBackground: async function(method) {
      let formData = new FormData();

      if (method == 'save') {
        this.$store.commit('isLoading', 'Adding Background Image');
        formData.append('image', this.backgroundImage[0]);

        try {
          await this.$store.getters.axios.post('/Todo/Settings/UpdateBackground', formData);
          this.$store.commit('addMessage', { message: 'Background Image Added!', type: 'success' });
          window.location.reload();
        } catch (e) {
          this.$store.commit('addMessage', { message: 'Background Image Not Added!', type: 'error' });
        }
      } else {
        this.$store.commit('isLoading', 'Removing Background Image');
        formData.append('image', null);

        try {
          await this.$store.getters.axios.post('/Todo/Settings/UpdateBackground', formData);
          this.$store.commit('addMessage', { message: 'Background Image Removed!', type: 'success' });
          window.location.reload();
        } catch (e) {
          this.$store.commit('addMessage', { message: 'Background Image Not Removed!', type: 'error' });
        }
      }

      this.$store.commit('isLoading', false);
    }
  },
  async mounted() {
    Object.keys(this.$store.state.defaultSettings).forEach(k => {
      this.$set(this.newSettings, k, this.$store.state.defaultSettings[k]);
    });

    this.$store.commit('isLoading', 'Getting Default Settings');
    let userSettings = (await this.$store.getters.axios.get('/Todo/Settings/GetSettings')).data;
    this.newSettings = userSettings['settings'];
    this.changeDefault();
    this.$store.commit('isLoading', false);

    if (!this.$store.state.defaultSettings['theme']) {
      this.newSettings['theme'] = 'white-bg';
      this.$store.state.defaultSettings['theme'] = 'white-bg';
    }

    this.compareSystemDefault();
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.reset-btn {
  margin-top: 20px;
}
</style>