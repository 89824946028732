/* eslint-disable */
import Vue from 'vue';
import VueX from 'vuex';
import axios from 'axios';

Vue.use(VueX);

export default new VueX.Store({
  state: {
    appVersion: '',

    accessKey: '',
    userDetails: null,

    loadMessage: '',

    messages: [],
    
    // Settings
    rowsPerPageList: [
      { text: '5 rows per page', value: 5 },
      { text: '10 rows per page', value: 10 },
      { text: '20 rows per page', value: 20 }
    ],
    statusList: [
      { text: 'All Status', value: 'all' },
      { text: 'Completed Only', value: 'completed' },
      { text: 'Incomplete Only', value: 'pending' }
    ],
    categoryList: [],
    priorityList: [
      { text: 'All Priority', value: 'all' },
      { text: 'High Priority', value: 2 },
      { text: 'Mid Priority', value: 1 },
      { text: 'Low Priority', value: 0 }
    ],
    sortingList: [
      { text: 'Created Date (Newest first)', value: 'start desc' },
      { text: 'Created Date (Oldest first)', value: 'start asc' },
      { text: 'Priority (Highest first)', value: 'priority desc' },
      { text: 'Priority (Lowest first)', value: 'priority asc' }
    ],
    themeList: [
      { text: 'Light', value: 'white-bg' },
      { text: 'Dark', value: 'black-bg' }
    ],

    defaultSettings: {
      'rowsPerPage': 10,
      'status': 'all',
      'category': 'all',
      'priority': 'all',
      'sort': 'start desc',
      'theme': 'white-bg'
    },
    systemDefaultSettings: {
      'rowsPerPage': 10,
      'status': 'all',
      'category': 'all',
      'priority': 'all',
      'sort': 'start desc',
      'theme': 'white-bg'
    }
  },
  mutations:{
    addMessage(state, msgObj) {
      state.messages.push(msgObj);
      setTimeout(() => {
        let index = state.messages.indexOf(msgObj)
        state.messages.splice(index, 1);
      }, 3000);
    },
    isLoading(state, message) {
      if (message) {
        state.loadMessage = message;
      } else {
        state.loadMessage = '';
      }
    },
    updateTags(state) {
      state.categoryList = [];
      state.categoryList.push({ text: 'All Categories', value: 'all' });
      this.getters.axios.get('/Todo/GetAvailableTag').then(result => {
        result.data.forEach(r => {
          state.categoryList.push({ text: r, value: r });
        });
      });
    }
  },
  getters: {
    axios: state => {
      return axios.create({
        baseURL: 'https://api.reeqzan.com',
        headers: { 'accesskey': state.accessKey }
      });
    },
    emailVerified: state => {
      if (!state.userDetails) {
        return false;
      } else {
        return state.userDetails['verify'];
      }
    }
  }
});