<template>
  <div class="tutorial-main">
    <div class="tutorial-content">
      <div class="tuto-title">Tutorial</div>
      <transition-group class="tuto-container" tag="div" :name="slideDirection">
        <div v-if="tutoIndex < tutoList.length - 1" :key="tutoIndex" class="animate-container">
          <img class="tuto-gif" :src="require(`@/assets/${tutoList[tutoIndex]['image']}.gif`)" alt="">
          <div class="caption">
            {{ tutoList[tutoIndex]['desc'] }}
          </div>
        </div>
        <div v-else :key="tutoIndex" class="animate-container">
          <div class="tuto-completed">
            Congratulations!<br>
            You have completed the tutorial!<br><br>
            You can always replay this tutorial by going to Profile and click 'How To Use' button.<br>
          </div>
        </div>
      </transition-group>
      <div class="pages-button">
        <Button @click.native="pagesClicked('Prev')" :disabled="tutoIndex == 0">Prev</Button>
        <div>{{ tutoIndex + 1 }} of {{ tutoList.length }}</div>
        <Button @click.native="pagesClicked(tutoIndex == tutoList.length - 1 ? 'Finish' : 'Next')">{{ tutoIndex == tutoList.length - 1 ? 'Finish' : 'Next' }}</Button>
      </div>
      <img class="close-tuto" @click="$store.state.userDetails.firstlogin = false" src="@/assets/cancel.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data: function() {
    return {
      tutoList: [
        { image: 'addTodo', desc: 'Click the bottom left green plus button to add a ToDo item.\nRemarks and attachment are optional.' },
        { image: 'viewAttachments', desc: 'To view the attachments click on the blue paperclip icon.\nPaperclip icon will only visible if there are any attachments for the ToDo item.\nFiles that are not viewable can be downloaded.' },
        { image: 'editItem', desc: 'Double click on the item to edit the item.\nClick the save icon once you are satisfied with your changes.' },
        { image: 'deleteAttachment', desc: 'While in the edit mode, click the paperclip icon and click the delete icon to delete the desired attachments.' },
        { image: 'addAttachment', desc: 'While in the edit mode, you may drag and drop the files that you would like to add as attachments' },
        { image: 'deleteItem', desc: 'You may delete any ToDo items.\nThis process cannot be undone.' },
        { image: 'completingItem', desc: 'Mark the checkbox to indicate that your ToDo item is completed.' },
        { image: '', desc: '' }
      ],
      tutoIndex: 0,
      slideDirection: 'slide-left'
    }
  },
  props: {
  },
  methods: {
    pagesClicked: function(type) {
      if (type == 'Next') {
        this.slideDirection = 'slide-left';
        this.tutoIndex++;
      } else if (type == 'Prev') {
        this.tutoIndex--;
        this.slideDirection = 'slide-right';
      } else if (type == 'Finish') {
        this.$store.state.userDetails.firstlogin = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tutorial-main {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 998;

  > .tutorial-content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 90%;
    height: calc(100% - 150px);
    max-width: 768px;
    align-items: center;
    
    > .tuto-title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 100%;
    }

    > .tuto-container {
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;

      > .animate-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;

        > .tuto-gif {
          height: 100%;
          object-fit: contain;
          width: 100%;
          background-color: gray;
        }

        > .caption {
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: pre-wrap;
          text-align: center;
          padding: 10px;
          width: 100%;
          box-sizing: border-box;
        }

        > .tuto-completed {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 100%;
          width: 100%;
          padding: 20%;
          box-sizing: border-box;
        }
      }
    }

    > .pages-button {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;

      > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    > .close-tuto {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 25px;
      cursor: pointer;
    }
  }
}
</style>