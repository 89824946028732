<template>
  <div class="home-main">
    <div class="header" :class="[ `${$store.state.defaultSettings['theme']}-header-footer` ]">
      <router-link class="flex-link" to="/Home">
        <img src="@/assets/ToDo.png" alt="" height="20">
        ToDo
      </router-link>
      <div class="navigation"></div>
      <router-link class="flex-link" to="/Home/Profile" title="Profile">
        <img src="@/assets/profile.png" alt="">
      </router-link>
      <router-link class="flex-link" to="/Home/Settings" title="Settings">
        <img src="@/assets/settings.png" alt="">
      </router-link>
      <img title="Logout" @click="logoutClick" src="@/assets/logout.png" alt="">
    </div>
    <div class="content">
      <div class="verify-bar" v-if="$store.getters['emailVerified']">
        Your email address is not yet verified. Click
        <router-link :to="`/Verify?username=${$store.state['userDetails']['username']}`">here</router-link> to verify.
      </div>
      <router-view></router-view>
      <div class="popup-messages">
        <transition-group name="msg" tag="p">
          <div v-for="item in $store.state.messages" v-bind:key="item" class="msg-item"
            :style="{ backgroundColor: item.type == 'success' ? 'rgb(159, 255, 159)' : item.type == 'error' ? 'rgb(255, 145, 145)' : 'rgb(254, 254, 153)' }">
            {{ item.message }}
          </div>
        </transition-group>
      </div>
    </div>
    <div class="footer" :class="[ `${$store.state.defaultSettings['theme']}-header-footer` ]">
      <div>ⓒ ReeqZan 2021 <span style="font-size: 0.75em">({{ $store.state.appVersion }})</span></div>
    </div>
    <Tutorial v-if="$store.state.userDetails ? $store.state.userDetails.firstlogin : false" />
  </div>
</template>

<script>
/* eslint-disable */
// Size of tutorial is 1024*689
import swal from 'sweetalert2';

export default {
  components: {
  },
  data: function() {
    return {
      loginLocation: null
    }
  },
  props: {
  },
  methods: {
    logoutClick: async function() {
      let logout = await swal.fire({
        title: 'Logout',
        html: `Are you sure you want to logout?`,
        icon: 'warning',
        confirmButtonText: 'Logout',
        confirmButtonColor: 'red',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'gray',
        showCancelButton: true
      });

      if (logout.isConfirmed) {
        try {
          await this.$store.getters.axios.get('/Todo/User/Logout');
          localStorage.removeItem('key');
          window.location.href = this.loginLocation.href;
        } catch (e) {
          console.log(e.response.data);
        }
      }
    }
  },
  async mounted() {
    this.loginLocation = this.$router.resolve('/');
    if (!localStorage.getItem('key')) {
      window.location.href = this.loginLocation.href;
      return;
    } else {
      try {
        await this.$store.getters.axios.get('/Todo/User/CheckAccessKey');
        try {
          let userDetails = (await this.$store.getters.axios.get('/Todo/User/GetUser')).data;
          this.$store.state.userDetails = userDetails;

          this.$store.commit('updateTags');

          let userSettings = (await this.$store.getters.axios.get('/Todo/Settings/GetSettings')).data;
          if (!userSettings['settings']['theme']) {
            this.$store.state.defaultSettings['theme'] = 'white-bg';
          } else {
            this.$store.state.defaultSettings['theme'] = userSettings['settings']['theme'];
          }
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        localStorage.removeItem('key');
        window.location.href = this.loginLocation.href;
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.home-main {
  width: 100%;

  > .header, > .footer {
    width: 100%;
    height: 50px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 10px gray;
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 99;
  }

  > .header {
    top: 0;

    > .flex-link {
      display: flex;
      align-items: center;

      > * {
        margin-right: 5px;
      }
    }

    > .navigation {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      > a {
        display: flex;
        align-items: center;

        > img {
          height: 25px;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      > .items {
        margin-left: 10px;
        text-decoration: none;
        color: unset;
      }
    }

    > a {
      text-decoration: none;
      color: unset;
      height: 100%;

      > img {
        height: 75%;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    > img {
      height: 75%;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  > .footer {
    position: relative;
  }

  > .content {
    padding: 10px;
    min-height: calc(100vh - 100px - 20px);
    position: relative;

    > .verify-bar {
      background-color: yellow;
      position: relative;
      left: -10px;
      top: -10px;
      width: calc(100%);
      padding: 10px;
    }

    > .popup-messages {
      position: fixed;
      top: 60px;
      right: 10px;
      z-index: 999;
    }
  }
}

.msg-item {
  transition: all 1s;
  margin-bottom: 5px;
  max-width: 200px;
  min-width: 200px;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 5px;
  text-align: right;
}
.msg-enter, .msg-leave-to {
  opacity: 0;
  transform: translateX(50px);
}
.msg-leave-active {
  position: absolute;
}
</style>