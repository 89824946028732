<template>
  <div class="input-main">
    <div class="label">
      <div v-if="showLabel">{{ label }}</div>
      <div v-if="loading" class="loader"></div>
      <div class="error-message">{{ error }}</div>
    </div>
    <input :type="type" @blur="focusOut()" v-model="privateText" @change="inputChanged" :disabled="disabled" :multiple="multiFile">
  </div>
</template>

<script>
export default {
  components: {
  },
  data: function() {
    return {
      privateText: ''
    }
  },
  props: {
    label: { type: String, default: 'Label' },
    type: { type: String, default: 'text' },
    error: { type: String, default: '' },
    text: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    files: { type: Array, default: function() { return [] } },
    multiFile: { type: Boolean, default: true },
    showLabel: { type: Boolean, default: true }
  },
  methods: {
    focusOut: function() {
      this.$emit('update:error', '');
      this.$emit('focusout');
    },
    inputChanged: function(e) {
      if (this.type == 'file') {
        this.$emit('update:files', e.target.files);
        return;
      }

      this.$emit('update:text', this.privateText);
    }
  },
  mounted() {
    if (this.text) {
      this.privateText = this.text;
    }
  },
  watch: {
    text: function(val) {
      this.privateText = val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styling/style.css';

.input-main {
  width: calc(100% - 0px);
  padding: 5px 0;

  > * {
    box-sizing: border-box;
  }

  > input {
    padding: 5px;
    width: 100%;
  }

  > .label {
    display: flex;
    align-items: center;
    position: relative;

    > div {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    > .error-message {
      color: red;
    }
  }
}

.loader {
  top: -4px;
}
</style>