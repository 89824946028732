<template>
  <div class="app-main">
    <div class="main-loader" v-if="$store.state.loadMessage">
      <div class="load-animation">
        <div v-for="i in 5" :key="i" class="load-ball"
          :style="{ animationDelay: `${i * 0.1}s` }"></div>
      </div>
      {{ $store.state.loadMessage }}
    </div>
    <router-view></router-view>
    <div v-if="!showBackgroundImage" class="version">{{ $store.state.appVersion }}</div>
    <div v-if="showBackgroundImage" class="background-img" :class="[ $store.state.defaultSettings['theme'] ]" ref="backImage"></div>
  </div>
</template>

<script>
import pkg from '../package.json';

export default {
  components: {
  },
  data: function() {
    return {
      showBackgroundImage: false
    }
  },
  props: {
  },
  methods: {
  },
  mounted() {
    this.$store.state.appVersion = `v${pkg.version}`;
    this.showBackgroundImage = this.$route.path != '/';
    this.$nextTick(() => {
      let back = this.$refs.backImage;
      if (back) {
        back.style.backgroundImage = `url(https://api.reeqzan.com/Todo/Settings/GetBackground?accesskey=${localStorage.getItem('key')})`;
      }
    });
  },
  beforeCreate() {
    if (localStorage.getItem('key')) {
      this.$store.state.accessKey = localStorage.getItem('key');
    }
  }
};
</script>

<style lang="scss" scoped>
.app-main {
  display: flex;
  align-items: center;
  justify-content: center;

  > .main-loader {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    text-align: center;
    font-weight: bold;
    backdrop-filter: blur(5px);

    > .load-animation {
      display: flex;

      > .load-ball {
        height: 20px;
        width: 20px;
        background-color: blue;
        border-radius: 100%;
        margin: 5px;
        animation: loading 1s infinite ease-in-out;
        animation-delay: 1s;
      }
    }
  }

  > .version {
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 999;
  }
}

.background-img {
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: -999999999;
  left: 0;
  top: 0;
  background-position: center;
}

@keyframes loading {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(0);
  }
}
</style>

<style lang="scss">
body {
  margin: 0;
  // padding: 10px;
  // font-family: 'Mirza', cursive;
  font-family: 'Bellota', cursive;
}

button {
  border-radius: 10px;
  background-color: transparent;
  padding: 10px 30px;
  transition: 0.1s;
  box-shadow: 3px 3px 5px gray;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
    box-shadow: 0 0 0 gray;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.slide-left-enter-active, .slide-left-leave-active,
.slide-right-enter-active, .slide-right-leave-active {
  transition: all 0.3s ease;
}
.slide-left-enter, .slide-right-leave-to {
  transform: translateX(100%);
}
.slide-left-leave-to, .slide-right-enter {
  transform: translateX(-100%); 
}

.slide-open-enter-active, .slide-open-leave-active {
  transition: 0.3s;
}

.slide-open-enter, .slide-open-leave-to {
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
}

// slide in from left
.top-slide-in-enter-active, .top-slide-in-leave-active {
  transition: 0.3s;
}

.top-slide-in-enter, .top-slide-in-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
// slide in from left

// Theme
.theme-container {
  padding: 5px;
  border-radius: 10px;
  width: fit-content;
  padding-right: 20px;
  
  > * {
    margin-bottom: 5px;
  }
  
  > .change-password {
    border: 1px solid gray;
    max-width: 768px;
    padding: 10px;
    box-shadow: 5px 5px 10px lightgray;
    margin: 15px 0;

    > .left-align-text {
      text-align: left;
    }
  }

  > .danger {
    background-color: red;
  }
}

.white-bg {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.white-bg-header-footer {
  background-color: rgb(245,245,245);
}

.black-bg, .black-bg select {
  background-color: black;
  color: white;
  border: 1px solid white !important;
}

.black-bg-header-footer {
  background-color: rgb(210,210,210);
}
// Theme

@media (max-width: 320px) {
  body, select, button, input {
    font-size: 10px;
  }

  button {
    padding: 5px 15px;
  }

  img {
    max-height: 15px;
  }

  .swal2-title {
    font-size: 14px !important;
  } 
  
  .swal2-html-container {
    font-size: 12px !important;
  }

  .swal2-actions > button {
    font-size: 12px !important;
  }

  .swal2-icon {
    height: 25px !important;
    width: 25px !important;
  }

  .swal2-icon-content {
    font-size: 25px !important;
  }

  .image {
    max-height: unset;
  }

  .add-todo-item {
    height: 35px;
    max-height: unset;
  }

  .msg-item {
    max-width: 100px !important;
    min-width: 100px !important;
  }

  .tuto-gif {
    max-height: unset;
  }

  .tuto-title {
    height: 30px !important;
  }

  .close-tuto {
    top: 5px !important;
    right: 5px !important;
  }
}

@media (min-width: 767px) {
  body {
    font-size: 18px;
  }
}
</style>