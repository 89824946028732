<template>
  <button :disabled="load || disabled" :style="{ boxShadow: load ? 'none' : '3px 3px 5px gray' }" @click="btnClick">
    <div :class="{ hide: load }">
      <slot></slot>
    </div>
    <div class="loader" :class="{ hide: !load }"></div>
  </button>
</template>

<script>
export default {
  components: {
  },
  props: {
    text: { type: String, default: 'Button' },
    load: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    btnClick: function(e) {
      this.$emit('click', e);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styling/style.css';

button {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.hide {
  opacity: 0;
}

.loader {
  position: absolute;
}
</style>