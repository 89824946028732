<template>
  <div class="todo-list-main">
    <div v-if="todoList" class="todo-container">
      <div class="header" :class="[ $store.state.defaultSettings['theme'] ]">
        <div >
          Found {{ todoList.filterCount }} of {{ todoList.totalCount }} ToDo lists
          <img class="refresh-table" src="@/assets/refresh.png" title="Refresh Data"
            @click="getData()" height="20" alt="">
        </div>
        <div class="pages">
          <button :disabled="page <= 1 || loading" @click="prevClick">Prev</button>
          <div>Page {{ page }} of {{ todoList.totalPages }}</div>
          <button :disabled="page >= todoList.totalPages || loading" @click="nextClick">Next</button>
        </div>
      </div>
      <div class="header" :class="[ $store.state.defaultSettings['theme'] ]">
        <div>
          Show : 
          <select v-model="filterAndSort['rowsPerPage']" @change="filterChanged">
            <option value="5">5 rows per page</option>
            <option value="10">10 rows per page</option>
            <option value="20">20 rows per page</option>
          </select>
        </div>
      </div>
      <div class="header" :class="[ $store.state.defaultSettings['theme'] ]">
        Filter
        <img class="filter-arrow" :style="{ transform: `scaleY(${showFilterSection ? -1 : 1})` }" @click="showFilterSection = !showFilterSection"
          src="@/assets/downArrow.png" alt="">
      </div>
      <!-- Filtering -->
      <div class="header" :class="[ $store.state.defaultSettings['theme'] ]">
        <transition name="top-slide-in" tag="div" class="filter-section" mode="out-in">
          <div v-if="showFilterSection" :key="showFilterSection">
            <div> <!-- Status Dropdown -->
              <select v-model="filterAndSort['status']" @change="filterChanged">
                <option v-for="(s, i) in $store.state.statusList" :key="i" :value="s['value']">{{ s['text'] }}</option>
              </select>
            </div>
            <div> <!-- Cateogry Dropdown -->
              <select v-model="filterAndSort['category']" @change="filterChanged">
                <option v-for="(c, i) in $store.state.categoryList" :key="i" :value="c['value']">{{ c['text'] }}</option>
              </select>
            </div>
            <div> <!-- Priority Dropdown -->
              <select v-model="filterAndSort['priority']" @change="filterChanged">
                <option v-for="(p, i) in $store.state.priorityList" :key="i" :value="p['value']">{{ p['text'] }}</option>
              </select>
            </div>
          </div>
          <div v-else :key="showFilterSection">
            Status : {{ `${filterAndSort['status'][0].toUpperCase()}${filterAndSort['status'].substring(1)}` }},
            Category : {{ `${filterAndSort['category'][0].toUpperCase()}${filterAndSort['category'].substring(1)}` }},
            Priority : {{ priorityMap[filterAndSort['priority']] }}
          </div>
        </transition>
      </div>
      <!-- Sorting -->
      <div class="header" :class="[ $store.state.defaultSettings['theme'] ]">
        Sort By : 
        <select v-model="filterAndSort['sort']" @change="filterChanged">
          <option v-for="(s, i) in $store.state.sortingList" :key="i" :value="s['value']">{{ s['text'] }}</option>
        </select>
      </div>

      <div v-if="loading" class="loading">
        <div class="loader"></div>
        <div>Loading {{ todoList.totalPages == 0 ? 'ToDo Items' : `page ${page} of ${todoList.totalPages}` }}</div>
      </div>
      
      <div class="todo-items" :class="[ $store.state.defaultSettings['theme'] ]" v-for="(t, i) in todoList.data" :key="i" :style="{ zIndex: t['id'] == edittingId ? 11 : 0 }">
        <div class="todo-column full-column">
          <div class="start-date">
            <div :class="[ 'priority', t['priority'] == 0 ? 'low' : t['priority'] == 1 ? 'mid' : 'high' ]" @click="priorityChanged"></div>
            <div>{{ getDateFormat(t['start']) }}</div>
            <div class="category">&nbsp;({{ t['tag'] }})</div>
            <div v-if="t['attachments'].length > 0" class="attach-icon">
              <img height="20" src="@/assets/attachment.png" @click="viewAttachment(t['id'], i)">
              <div class="attach-count">{{ t['attachments'].length }}</div>
            </div>
            <Input v-if="t['id'] == edittingId" style="width: fit-content" :showLabel="false" type="file" :files.sync="editFiles"></Input>
          </div>
          <div v-if="t['targetdate']" class="start-date due-time" :class="[ !t['end'] && isDue(t['targetdate']) ? 'due-pass' : '' ]">
            Due on : {{ getDateFormat(t['targetdate']) }}
          </div>
          <div class="item" :ref="`item${t['id']}`" :contenteditable="t['editting']" @click="editContent(t['id'])">{{ t['item'] }}</div>
          <div :contenteditable="t['editting']" :ref="`remarks${t['id']}`" class="remarks">{{ t['remarks'] ? t['remarks'] : '-' }}</div>
        </div>
        <div class="todo-column flex" v-if="t['id'] == edittingId">
          <img class="save-cancel-icon" src="@/assets/save.png" @click="editClicked('save')" height="20px" alt="">
          <img class="save-cancel-icon" src="@/assets/cancel.png" @click="editClicked('cancel')" height="20px" alt="">
        </div>
        <div class="todo-column flex">
          <div class="start-date">{{ getDateFormat(t['end']) }}</div>
          <input type="checkbox" @input="checkboxChanged(t['id'], $event)" :checked="t['end']">
          <img class="delete-icon" @click="deleteItem(t['item'], t['id'])" src="@/assets/delete.png" alt="">
        </div>
      </div>

      <div v-if="edittingId" class="disabled"></div>
      <div class="footer">
        
      </div>
    </div>
    <img class="add-todo-item" src="@/assets/add.png" height="50px" alt="" @click="showAddPopup = true">
    <div v-if="showAddPopup" class="popup-container">
      <div class="popup-content">
        <div class="title">Add Your ToDo List</div>
        <Input label="ToDo Item" :text.sync="newItem" />
        <div class="new-item-full-flex">
          Priority :
          <select v-model="newPriority">
            <option value="0">Low</option>
            <option value="1">Mid</option>
            <option value="2">High</option>
          </select>
        </div>
        <div class="new-item-full-flex">
          Category :
          <select v-model="newCategory" :disabled="isNewCategory">
            <option v-for="(c, ci) in newCategoryList" :key="ci" :value="c['value']">{{ c['text'] }}</option>
          </select>
        </div>
        <div class="new-item-full-flex">
          <input id="catCheck" type="checkbox" v-model="isNewCategory" :disabled="newCategoryList.length == 0">
          <label for="catCheck" style="white-space: nowrap">New Category :&nbsp;</label>
          <Input :showLabel="false" :disabled="!isNewCategory" :text.sync="addNewCategory"></Input>
        </div>
        <Input label="Remarks" :text.sync="newRemarks" />
        <Input label="Attachment" type="file" :files.sync="newFiles" />
        <br>
        <div class="new-item-full-flex">
          <input id="dueCheck" type="checkbox" v-model="hasDueDate">
          <label for="dueCheck">Due Date</label>
        </div>
        <div class="new-item-full-flex">
          <input type="date" :min="minDueDate" v-model="newDueDate" :disabled="!hasDueDate" @change="dueDateChanged">
          <select v-model="newDueTime" :disabled="!hasDueDate" @change="dueTimeChanged">
            <option v-for="(t, ti) in timeList" :key="ti" :value="t"
              :disabled="parseInt(t) < parseInt(minDueTime)">{{ t }}</option>
          </select>
        </div>

        <div class="new-item-full-flex">
          <input id="remindCheck" type="checkbox" v-model="setReminder" :disabled="!hasDueDate" @change="setReminderCheck">
          <label for="remindCheck">Remind Me at</label>
        </div>
        <div class="new-item-full-flex">
          <input type="date" :min="minReminderDate" :max="newDueDate" v-model="reminderDate" :disabled="!setReminder" @change="reminderChanged">
          <select v-model="reminderTime" :disabled="!setReminder">
            <option v-for="(t, ti) in timeList" :key="ti" :value="t"
              :disabled="parseInt(t) < parseInt(minReminderTime) || parseInt(t) > parseInt(maxReminderTime)">{{ t }}</option>
          </select>
        </div>

        <div class="error">{{ newError }}</div>
        <button class="add" @click="addNewItem">Add</button>
        <button class="cancel" @click="showAddPopup = false">Cancel</button>
      </div>
    </div>
    <div v-if="showAttachment" class="popup-container">
      <div class="popup-content attach-content">
        <img class="close-attachment" src="@/assets/cancel.png" @click="showAttachment = false" alt="">
        <div class="title">Attachments ({{ attachmentIndex + 1 }} of {{ attachmentsItem.length }})</div>
        <transition-group class="attachment" :name="transitionDirection" tag="div">
          <object class="image" :key="attachmentIndex" :data="attachmentsItem[attachmentIndex]['file']" :type="attachmentsItem[attachmentIndex]['filetype']"></object>
          <!-- <img class="image" :src="attachmentsItem[attachmentIndex]['file']" :key="attachmentIndex"> -->
          <div class="caption" :key="attachmentIndex">
            <div>
              {{ attachmentsItem[attachmentIndex]['filename'] }}
            </div>
          </div>
        </transition-group>
        <a v-if="!edittingId" :href="attachmentsItem[attachmentIndex]['file']" download target="_blank">Download this attachment</a>
        <img class="delete-attachment" @click="deleteAttachment" v-if="edittingId" src="@/assets/delete.png" alt="">
        <img class="arrow left" @click="changeAttachment('previous')" src="@/assets/leftArrow.png" alt="">
        <img class="arrow right" @click="changeAttachment('next')" src="@/assets/rightArrow.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import swal from 'sweetalert2';

export default {
  components: {
  },
  data: function() {
    return {
      loading: false,

      todoList: {
        totalCount: 0,
        totalPages: 0
      },
      page: 1,

      filterAndSort: {
        'rowsPerPage': 20,
        'status': 'all',
        'category': 'all',
        'priority': 'all',
        'sort': 'start desc'
      },
      priorityMap: {
        all: 'All',
        2: 'High',
        1: 'Mid',
        0: 'Low'
      },

      showFilterSection: false,

      currentEditItem: null,
      edittingId: null,
      originalEditItem: '',
      originalPriority: null,
      isEdit: false,

      editTimeOut: null,

      showAddPopup: false,

      editFiles: [],

      timeList: [
        '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
        '16:00', '17:00', '18:00', '19:00', '20:00', '21:00',
      ],

      newItem: '',
      newPriority: 0,
      newCategoryList: [],
      newCategory: '',
      newRemarks: '',
      newFiles: [],
      newError: '',
      newDueDate: '',
      minDueDate: '',
      newDueTime: '09:00',
      minDueTime: '',
      reminderDate: '',
      minReminderDate: '',
      reminderTime: '',
      minReminderTime: '',
      maxReminderTime: '',

      isNewCategory: false,
      hasDueDate: false,
      setReminder: false,
      addNewCategory: '',
      
      showAttachment: false,
      attachmentsItem: null,
      attachmentIndex: 0,
      transitionDirection: 'slide-left'
    }
  },
  computed: {
    smtg: function() {
      return this.attachmentIndex;
    }
  },
  methods: {
    prevClick: function() {
      this.page--;
      this.getData();
    },
    nextClick: function() {
      this.page++;
      this.getData();
    },

    filterChanged: function() {
      this.page = 1;
      this.getData();
    },
    getData: async function() {
      if (this.todoList) {
        this.todoList.data = [];
      }
      this.loading = true;

      let url = '/Todo/GetData';
      url += `?row=${this.filterAndSort['rowsPerPage']}`;
      url += `&page=${this.page}`;
      url += `&showFilter=${this.filterAndSort['status']}`;
      url += `&tag=${this.filterAndSort['category']}`;
      url += `&priority=${this.filterAndSort['priority']}`;
      url += `&sorting=${this.filterAndSort['sort']}`;
      
      this.todoList = (await this.$store.getters.axios.get(url)).data;
      this.todoList.data.forEach(t => {
        this.$set(t, 'editting', false);
      });
      this.loading = false;

      if (this.page > this.todoList.totalPages) {
        this.page = this.todoList.totalPages;
        this.getData();
      }
    },
    editContent: function(id) {
      let data = this.todoList.data.find(t => t['id'] == id);

      if (this.editTimeOut) {
        clearTimeout(this.editTimeOut);
        this.edittingId = id;
        this.originalEditItem = data['item'];
        this.editTimeOut = null;
        this.currentEditItem = data;
        this.editFiles = [];
        this.originalPriority = data['priority'];
        return;
      }

      if (data['editting']) { return }
      this.$set(data, 'editting', true);

      this.editTimeOut = setTimeout(() => {
        this.$set(data, 'editting', false);
        clearTimeout(this.editTimeOut);
        this.editTimeOut = null;
      }, 300);
    },
    checkboxChanged: async function(id, e) {
      let obj = this.todoList.data.find(d => d['id'] == id);
      let oriEnd = obj['end'];
      let oriStatus = obj['status'];

      if (e.target.checked) {
        let date = new Date();
        obj['end'] = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
        obj['status'] = 'Completed';
      } else {
        let endDate = new Date(oriEnd);
        let confirmIncomplete = await swal.fire({
          title: 'Mark Incomplete Item',
          html: `This item was marked as completed at ${this.getDateFormat(endDate)}<br><br>Are you sure you want to mark it incomplete?`,
          icon: 'warning',
          confirmButtonText: 'Incomplete',
          confirmButtonColor: 'red',
          cancelButtonText: 'Cancel',
          cancelButtonColor: 'lightgray',
          showCancelButton: true
        });
        
        if (!confirmIncomplete.isConfirmed) {
          e.target.checked = true;
          return;
        }
        obj['end'] = null;
        obj['status'] = 'Pending';
      }
      
      this.$store.commit('isLoading', 'Updating Status');
      try {
        await this.$store.getters.axios.post('/Todo/UpdateData', this.getFormData(obj));
        this.$store.commit('addMessage', { message: 'Status Updated!', type: 'success' });
      } catch (err) {
        obj['end'] = oriEnd;
        obj['status'] = oriStatus;
        e.target.checked = oriEnd;
        this.$store.commit('addMessage', { message: 'Status not Updated!', type: 'error' });
      }
      this.$store.commit('isLoading', false);
    },
    priorityChanged: function() {
      if (this.edittingId) {
        let data = this.todoList.data.find(t => { return t['id'] == this.edittingId });
        data['priority']++;
        if (data['priority'] > 2) {
          data['priority'] = 0;
        }
      }
    },

    getDateFormat: function(date) {
      if (!date) {
        return '';
      }

      let dateObject = new Date(date);
      let dat = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate();
      let mon = dateObject.getMonth() + 1 < 10 ? `0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1;
      let yrs = dateObject.getFullYear();
      let hrs = dateObject.getHours() < 10 ? `0${dateObject.getHours()}` : dateObject.getHours();
      let min = dateObject.getMinutes() < 10 ? `0${dateObject.getMinutes()}` : dateObject.getMinutes();
      return `${dat}/${mon}/${yrs} ${hrs}:${min}`;
    },
    isDue: function(date) {
      let dueDate = new Date(date);
      let now = new Date();

      return dueDate.getTime() < now.getTime();
    },

    editClicked: async function(item) {
      var data = this.todoList.data.find(t => t['id'] == this.edittingId);
      let oriItem = data['item'];
      let oriRemarks = data['remarks'];

      this.$set(data, 'editting', false);
      let itemDiv = this.$refs[`item${this.edittingId}`][0];
      let remarksDiv = this.$refs[`remarks${this.edittingId}`][0];

      if (item == 'save') {
        let itemText = itemDiv.innerHTML;
        itemText = itemText.split('<div>').join('\n');
        itemText = itemText.split('</div>').join('');
        itemText = itemText.split('<br>').join('');
        data.item = itemText;
        itemDiv.innerHTML = '';
        itemDiv.innerHTML = itemText;

        let remarksText = remarksDiv.innerHTML;
        remarksText = remarksText.split('<div>').join('\n');
        remarksText = remarksText.split('</div>').join('');
        data.remarks = remarksText;
        remarksDiv.innerHTML = '';
        remarksDiv.innerHTML = remarksText;

        this.$store.commit('isLoading', 'Updating Item');

        try {
          await this.$store.getters.axios.post('/Todo/UpdateData', this.getFormData(data));
          this.$store.commit('addMessage', { message: 'Item Updated!', type: 'success' });
        } catch (e) {
          this.$store.commit('addMessage', { message: 'Error Updating', type: 'error' });
          itemDiv.innerHTML = '';
          remarksDiv.innerHTML = '';
          itemDiv.innerHTML = oriItem;
          remarksDiv.innerHTML = oriRemarks;
        }

        if (this.editFiles.length > 0) {
          try {
            let formData = new FormData();
            this.editFiles.forEach(f => {
              if (f['size'] <= 4100000) {
                formData.append('file', f);
              }
            });
            let add = await this.$store.getters.axios.post(`/Todo/AddAttachment?itemid=${this.edittingId}`, formData);
            let data = this.todoList.data.find(d => d['id'] == this.edittingId);
            add.data.forEach(a => {
              data['attachments'].push(a);
            });
            this.$store.commit('addMessage', { message: 'Attachment Added', type: 'success' });
          } catch (e) {
            this.$store.commit('addMessage', { message: 'Attachment Not Added', type: 'error' });
          }
        }
        
        this.$store.commit('isLoading', false);
      } else if (item == 'cancel') {
        itemDiv.innerHTML = '';
        remarksDiv.innerHTML = '';
        itemDiv.innerHTML = data.item;
        remarksDiv.innerHTML = data.remarks;
        data['priority'] = this.originalPriority;
      }
      
      this.edittingId = null;
      this.originalEditItem = '';
    },
    getFormData: function(obj) {
      let formData = new FormData();
      formData.append('id', obj['id']);
      formData.append('item', obj['item']);
      formData.append('end', obj['end'] ? obj['end'] : '');
      formData.append('status', obj['status']);
      formData.append('remarks', obj['remarks']);
      formData.append('priority', obj['priority']);

      return formData;
    },

    addNewItem: async function() {
      this.newError = '';
      if (!this.newItem) {
        this.newError = 'Your item cannot be empty';
        return;
      }

      let category = '';

      if (this.isNewCategory && !this.addNewCategory) {
        this.newError = 'Your category cannot be empty';
        return;
      } else if (this.isNewCategory) {
        category = this.addNewCategory;
      } else {
        category = this.newCategory;
      }
      
      try {
        this.$store.commit('isLoading', 'Adding Item');

        let formData = new FormData();
        formData.append('item', this.newItem);
        formData.append('remarks', this.newRemarks);
        formData.append('priority', this.newPriority);
        formData.append('tag', category);
        formData.append('start', `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}`);

        if (this.newFiles.length > 0) {
          for (let i = 0; i < this.newFiles.length; i++) {
            formData.append(`file`, this.newFiles[i]);
          }
        }
        
        if (this.hasDueDate) {
          formData.append('targetdate', `${this.newDueDate} ${this.newDueTime}`);
        }
        if (this.setReminder) {
          formData.append('reminderdate', `${this.reminderDate} ${this.reminderTime}`);
        }

        await this.$store.getters.axios.post('/Todo/AddData', formData);
        this.showAddPopup = false;

        this.page = 1;
        await this.getData();

        this.$store.commit('addMessage', { message: 'Item Added!', type: 'success' });

        this.$store.commit('updateTags');
      } catch (e) {
        this.$store.commit('addMessage', { message: 'Error Adding Item', type: 'error' });
      }
      
      this.$store.commit('isLoading', false);
    },
    deleteItem: async function(text, id) {
      let deleteMsg = document.createElement('div');
      deleteMsg.innerHTML = `Are you sure you want to delete ToDo item;<br/>`;

      let item = document.createElement('div');
      item.innerHTML = text;
      item.style.whiteSpace = 'pre';

      deleteMsg.innerHTML += item.outerHTML;

      let toDelete = await swal.fire({
        title: 'Delete ToDo',
        html: deleteMsg.outerHTML,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: 'red',
        cancelButtonColor: 'gray'
      });

      if (toDelete.isConfirmed) {
        this.$store.commit('isLoading', 'Deleting, please wait');
        try {
          await this.$store.getters.axios.delete(`/Todo/DeleteData?id=${id}`);
          this.$store.commit('addMessage', { message: 'Item Deleted', type: 'success' });
          this.getData();
          this.$store.commit('updateTags');
        } catch (e) {
          this.$store.commit('addMessage', { message: 'Error Deleting Item', type: 'error' });
        }
        this.$store.commit('isLoading', false);
      }
    },
    viewAttachment: function(id, index) {
      this.showAttachment = true;
      this.attachmentIndex = 0;
      this.attachmentsItem = this.todoList['data'][index]['attachments'];
    },
    changeAttachment: function(direction, isDelete) {
      let previousIndex = this.attachmentIndex;
      switch (direction) {
        case 'previous':
          if (this.attachmentIndex == 0) {
            this.attachmentIndex = this.attachmentsItem.length - 1;
          } else {
            this.attachmentIndex--;
          }
          this.transitionDirection = 'slide-right';
          break;
        case 'next':
          if (this.attachmentIndex == this.attachmentsItem.length - 1) {
            this.attachmentIndex = 0;
          } else {
            this.attachmentIndex++;
          }
          this.transitionDirection = 'slide-left';
          break;
      }
      
      if (isDelete) {
        return previousIndex;
      }
    },
    deleteAttachment: async function() {
      this.$store.commit('isLoading', 'Deleting Attachment');
      
      try {
        await this.$store.getters.axios.delete(`/Todo/DeleteAttachment?id=${this.attachmentsItem[this.attachmentIndex]['id']}`);

        this.$store.commit('addMessage', { message: 'Attachment Deleted', type: 'success' });
        let previous = this.changeAttachment('previous', true);
        if (previous == 0) {
          this.attachmentIndex--;
        }
        setTimeout(() => {
          this.attachmentsItem.splice(previous, 1);
          if (this.attachmentsItem.length == 0) {
            this.showAttachment = false;
            swal.fire('No attachments', `You've deleted all the attachments for this ToDo item`, 'info');
          }
        }, 300);
      } catch (e) {
        this.$store.commit('addMessage', { message: 'Attachment is not deleted', type: 'error' });
      }
      
      this.$store.commit('isLoading', false);
    },
    checkBigFiles: function(data) {
      let bigFiles = [];
      data.forEach(v => {
        if (v['size'] > 4100000) {
          bigFiles.push(v['name']);
        }
      });
      
      if (bigFiles.length > 0) {
        swal.fire('Files too big', `Below files will not be uploaded since it is bigger than 4MB<br/>${bigFiles.join('<br/>')}`, 'warning');
      }
    },
    keyDown: function(e) {
      let ctrlEnter = e['key'] == 'Enter' && (e['ctrlKey'] || e['metaKey']);
      let esc = e['key'] == 'Escape';

      if (this.edittingId) {
        if (ctrlEnter) {
          this.editClicked('save');
        } else if (e['key'] == 'Escape') {
          this.editClicked('cancel');
        }
      } else if (this.showAddPopup) {
        if (ctrlEnter) {
          this.addNewItem();
        } else if (e['key'] == 'Escape') {
          this.showAddPopup = false;
        }
      }
    },

    setReminderCheck: async function(e) {
      let isChecked = e.target.checked;
      
      if (isChecked && !this.$store.state['userDetails']['telegramname']) {
        await swal.fire({
          title: 'Cannot Create Reminder',
          html: `Sorry, at the moment the reminder is only available if you've linked your telegram account.<br><br>To link your telegram account, please go to Profile page.`,
          icon: 'error'
        });
        this.setReminder = false;
      }
    },

    dueDateChanged: function(e) {
      let value = e.target.value;
      let now = new Date();

      let nowCompare = new Date();
      nowCompare.setHours(0, 0, 0, 0);

      let newDue = new Date(value);
      newDue.setHours(0, 0, 0, 0);

      let currentReminder = new Date(this.reminderDate);
      currentReminder.setHours(0, 0, 0, 0);

      if (nowCompare.getTime() == newDue.getTime()) {
        let hour = now.getHours() + 1;
        this.minDueTime = `${hour < 10 ? `0${hour}` : hour}:00`;

        if (parseInt(this.newDueTime) < parseInt(this.minDueTime)) {
          this.newDueTime = `${hour < 10 ? `0${hour}` : hour}:00`;
        }
      } else {
        this.minDueTime = '09:00';

        if (newDue.getTime() == currentReminder.getTime()) {
          this.maxReminderTime = this.newDueTime;
        } else {
          this.maxReminderTime = '21:00';
        }
      }
    },
    dueTimeChanged: function(e) {
      let value = e.target.value;

      let currentReminder = new Date(this.reminderDate);
      currentReminder.setHours(0, 0, 0, 0);

      let currentDue = new Date(this.newDueDate);
      currentDue.setHours(0, 0, 0, 0);

      if (currentReminder.getTime() == currentDue.getTime()) {
        this.maxReminderTime = value;

        if (parseInt(this.reminderTime) > parseInt(this.maxReminderTime)) {
          this.reminderTime = this.maxReminderTime;
        }
      }
    },
    reminderChanged: function(e) {
      let value = e.target.value;

      let now = new Date();

      let nowDate = new Date();
      nowDate.setHours(0, 0, 0, 0);

      let newReminder = new Date(value);
      newReminder.setHours(0, 0, 0, 0);

      let currentDue = new Date(this.newDueDate);
      currentDue.setHours(0, 0, 0, 0);

      if (newReminder.getTime() == currentDue.getTime()) {
        this.minReminderTime = '09:00';
        this.maxReminderTime = this.newDueTime;
      } else if (newReminder.getTime() == nowDate.getTime()) {
        let hours = now.getHours() + 1;
        this.minReminderTime = `${hours < 10 ? `0${hours}` : hours}:00`;
        this.maxReminderTime = '21:00'
      } else {
        this.minReminderTime = '09:00';
        this.maxReminderTime = '21:00'
      }

      if (parseInt(this.reminderTime) < parseInt(this.minReminderTime)) {
        this.reminderTime = this.minReminderTime;
      } else if (parseInt(this.reminderTime) > parseInt(this.maxReminderTime)) {
        this.reminderTime = this.maxReminderTime;
      }
    }
  },
  async mounted() {
    document.addEventListener('keydown', (e) => {
      this.keyDown(e);
    });
    
    this.$store.commit('isLoading', 'Getting Default Settings');
    let userSettings = (await this.$store.getters.axios.get('/Todo/Settings/GetSettings')).data;
    this.filterAndSort = userSettings['settings'];
    this.$store.commit('isLoading', false);

    await this.getData();
  },
  watch: {
    showAddPopup: function(val) {
      if (!val) {
        this.newItem = '';
        this.newRemarks = '';
        this.newFiles = [];
        this.newPriority = 0;

        if (this.newCategoryList.length > 0) {
          this.newCategory = this.newCategoryList[0]['text'];
        }

        this.addNewCategory = '';
        this.isNewCategory = false;

        this.newDueDate = '';
        this.newDueTime = '09:00';
        this.hasDueDate = false;
        this.setReminder = false;
      } else {
        this.newCategoryList = this.$store.state.categoryList.filter((c, i) => { return i > 0 });
        if (this.newCategoryList.length == 0) {
          this.isNewCategory = true;
        } else {
          this.newCategory = this.newCategoryList[0]['text'];
        }

        let date = new Date();

        if (date.getHours() < 9) {
          this.newDueTime = '09:00';
          this.minDueTime = '09:00';

          this.reminderTime = '09:00';
          this.minReminderTime = '09:00';
        } else if (date.getHours() >= 21) {
          date.setDate(date.getDate() + 1);
          this.newDueTime = '09:00';
          this.minDueTime = '09:00';

          this.reminderTime = '09:00';
          this.minReminderTime = '09:00';
        } else {
          let hour = date.getHours() + 1;
          this.newDueTime = `${hour < 10 ? `0${hour}` : hour}:00`;
          this.minDueTime = `${hour < 10 ? `0${hour}` : hour}:00`;
          
          this.reminderTime = `${hour < 10 ? `0${hour}` : hour}:00`;
          this.minReminderTime = `${hour < 10 ? `0${hour}` : hour}:00`;
        }

        let year = date.getFullYear();
        let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        this.newDueDate = `${year}-${month}-${day}`;
        this.minDueDate = `${year}-${month}-${day}`;

        this.reminderDate = `${year}-${month}-${day}`;
        this.minReminderDate = `${year}-${month}-${day}`;

        this.maxReminderTime = this.newDueTime;
      }
    },
    editFiles: function(val) {
      if (val.length > 0) {
        this.checkBigFiles(val);
      }
    },
    newFiles: function(val) {
      if (val.length > 0) {
        this.checkBigFiles(val);
      }
    },
    hasDueDate: function(val) {
      if (!val) {
        this.setReminder = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.todo-list-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > .todo-container {
    width: 100%;
    max-width: 768px;
    position: relative;

    > .loading {
      display: flex;
      justify-content: center;
      align-items: center;

      > .loader {
        margin-right: 10px;
      }
    }

    > .header, > .footer {
      display: flex;
      align-items: center;
    }

    > .header {
      margin-bottom: 5px;
      flex-wrap: wrap;
      padding: 10px;
      border-radius: 10px;

      > div {
        display: flex;
        align-items: center;

        > .refresh-table {
          margin-left: 5px;
          cursor: pointer;
        }
      }

      > .pages {
        display: flex;
        // justify-content: center;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;

        > * {
          margin: 0 5px;
        }

        > button {
          padding: 3px 10px;
        }
      }

      > .filter-arrow {
        height: 20px;
        margin-left: 5px;
        cursor: pointer;
        transition: 0.3s;
      }
    }

    > .filter-section {
      overflow: hidden;
      position: relative;

      > div {
        position: sticky;
        left: 0;
        top: 0;
      }
    }

    > .footer {
      margin-top: 10px;
      position: sticky;
      bottom: 0;
    }

    > .disabled {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(128,128,128,0.3);
      // opacity: 0.2;
      height: 100%;
      width: 100%;
      z-index: 10;
    }
    
    > .todo-items {
      border: 1px solid black;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      margin-top: 10px;
      display: flex;
      align-items: center;
      position: relative;

      > .todo-column {
        > .save-cancel-icon {
          margin: 0 5px;
        }

        > .item, > .remarks {
          font-weight: bold;
          padding: 3px;
          white-space: pre-wrap;
          word-break: break-word;

          &:focus {
            border-bottom: 1px solid gray;
            outline: none;
          }
        }

        > .remarks {
          font-size: 0.8em;
          font-weight: normal;
        }

        > .start-date {
          font-size: 0.8em;
          display: flex;
          align-items: center;

          > .priority {
            border-radius: 100%;
            height: 10px;
            width: 10px;
            margin-right: 5px;
            border: 1px solid black;
          }

          > .low {
            background-color: gray;
          }

          > .mid {
            background-color: yellow;
          }

          > .high {
            background-color: red;
          }

          > .category {
            font-style: italic;
          }
          
          > .attach-icon {
            margin-left: 5px;
            display: flex;
            align-items: center;

            > img {
              cursor: pointer;
            }

            > .attach-count {
              right: 0;
              top: 0;
              height: 10px;
              width: 10px;
              background-color: blue;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 3px;
              border-radius: 100%;
            }
          }
          
          > input {
            margin-left: 5px;
            width: fit-content;
          }
        }

        > .due-time {
          margin-bottom: 5px;
        }

        > .due-pass {
          color: red;
        }

        > .delete-icon {
          height: 20px;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      > .full-column {
        width: 100%;
      }

      > .flex {
        display: flex;
        align-items: center;
        width: max-content;
        white-space: nowrap;
      }
    }
  }

  > .add-todo-item {
    position: fixed;
    bottom: 60px;
    right: 10px;
    cursor: pointer;
  }

  > .popup-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(128,128,128,0.3);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 12;
    overflow: auto;
    padding: 60px 0;
    box-sizing: border-box;

    > .popup-content {
      width: 90%;
      height: fit-content;
      max-width: 768px;
      background-color: white;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;

      > .title {
        font-weight: bold;
      }

      > button {
        max-width: 50%;
        margin-top: 10px;
      }

      > .add { background-color: rgb(147, 147, 255); }
      > .cancel { background-color: rgb(230, 106, 106); }

      > .new-item-full-flex {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    > .attach-content {
      height: calc(100% - 150px);
      position: relative;

      > .close-attachment {
        position: absolute;
        right: 10px;
        top: 10px;
        height: 20px;
        cursor: pointer;
      }

      > .title {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > .delete-attachment {
        height: 25px;
        cursor: pointer;
      }

      > .attachment {
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        object-fit: contain;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .image {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: calc(100% - 50px);
          background-color: lightgray;
          border: 1px solid lightgray;
          object-fit: contain;
          // background-image: url('https://image.shutterstock.com/image-vector/ui-image-placeholder-wireframes-apps-260nw-1037719204.jpg');
          // background-position: center;
          // background-repeat: no-repeat;
        }

        > .caption {
          position: absolute;
          left: 0;
          min-width: 0;
          top: calc(100% - 50px);
          z-index: 1;
          height: 50px;
          width: calc(100% - 18px);
          text-align: center;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          > div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      > .arrow {
        position: absolute;
        height: 50px;
        background-color: gray;
        z-index: 99;
        top: calc(50% - 25px);
        transition: 0.3s;
        opacity: 0.3;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      > .left {
        left: 10px;
      }

      > .right {
        right: 10px;
      }
    }
  }
}
</style>